<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            <span v-html="item.text"></span>
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col md="12">
      <v-sheet color="white" elevation="1" class="pa-5">
        <v-alert
          outlined
          type="warning"
          prominent
          border="left"
          v-if="dataError"
        >
          No data to display. Please try again or select different report from
          <a href="/dashboard/managementreports"
            ><strong>Financial & Management Reports</strong></a
          >
          section.
        </v-alert>
        <v-alert outlined type="error" v-if="criticalError">
          Report was not requested with
          <a href="/dashboard/managementreports"
            ><strong>Financial & Management Reports</strong></a
          >. Please visit
          <a href="/dashboard/managementreports"
            ><strong>Financial & Management Reports</strong></a
          >
          and select report again.
        </v-alert>

        <v-expand-transition>
          <FrequencyTable
            v-if="showTable && tableData"
            :tableData="tableData"
            :TableType="this.$route.params.obj.repName"
        /></v-expand-transition>
        <v-divider
          class="mt-5"
          v-if="
            !(
              showRatingsProgress ||
              ratingsData ||
              showComplaintsTable ||
              showADIVehicleTable ||
              showFinancialTables
            )
          "
        />
        <v-expand-transition>
          <ColumnChart
            v-if="showTable && tableData"
            :tableData="tableData"
            :ageRatios="true"
            :genderRatios="true"
            :geoAreas="true"
            :gearRatios="true"
            :TableType="this.$route.params.obj.repName"
        /></v-expand-transition>
        <v-row v-if="tableData" dense>
          <v-col md="6">
            <GeneralLineChart
              :data-array="lineChartAgeData"
              chart-title="Age Ratios"
              vAxisTitle="Total"
              hAxisTitle="Age Groups"
              :chart-hight="600"
            />
          </v-col>
          <v-col md="6">
            <GeneralLineChart
              :data-array="lineChartGenderData"
              chart-title="Gender Ratios"
              vAxisTitle="Total"
              hAxisTitle="Genders"
              :chart-hight="600"
            />
          </v-col>
          <v-col md="6">
            <GeneralLineChart
              :data-array="lineChartGearData"
              chart-title="Gear Ratios"
              vAxisTitle="Total"
              hAxisTitle="Gears"
              :chart-hight="600"
            />
          </v-col>
          <v-col md="6">
            <GeneralLineChart
              :data-array="lineChartGeoData"
              chart-title="Geographical Areas"
              vAxisTitle="Total"
              hAxisTitle="Geographical Area"
              :chart-hight="600"
            />
          </v-col>
        </v-row>
        <!-- Ratings Reports -->
        <v-expand-transition>
          <div>
            <ProgressBars
              v-if="showRatingsProgress && ratingsData"
              :ratingsData="ratingsData"
              :reportType="this.$route.params.obj.repName"
            />
            <!-- <h1>Rating goes here</h1> -->
          </div>
        </v-expand-transition>
        <!-- Complaints reports -->
        <v-expand-transition>
          <div>
            <ComplaintsReportView
              v-if="showComplaintsTable && complaintsData"
              :tableData="complaintsData"
              :subreport="selectedReport"
              :statusData="compalintStatus"
              :geoData="outpcData"
              :allData="allCompData"
            />
          </div>
        </v-expand-transition>
        <!-- <v-expand-transition>
          <div>
            <ComplaintsColumnChart
              v-if="showComplaintsTable && complaintsData"
              :tableData="complaintsData"
            />
          </div>
        </v-expand-transition> -->
        <!-- Instructor Reports -->
        <v-expand-transition>
          <div>
            <InstructorReportView
              v-if="showADIVehicleTable && adiVehicleData"
              :tableData="adiVehicleData"
              :reportNo="selectedReport"
            />
          </div>
        </v-expand-transition>
        <!-- Financial Reports -->

        <v-expand-transition>
          <div>
            <FinancialReportView
              v-if="showFinancialTables"
              :tableData="financialTableData"
              :reportNo="selectedReport"
              :reportName="this.$route.params.obj.repName.text"
            />
          </div>
        </v-expand-transition>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import FrequencyTable from "@/components/Reports/FrequencyTable";
import ColumnChart from "@/components/Reports/Charts/ColumnChart";
import ProgressBars from "@/components/Reports/Ratings/ProgressBars";
import ComplaintsReportView from "@/components/Reports/ComplaintsReportView";

import GeneralLineChart from "@/components/Reports/Charts/GeneralLineChart";
// import ComplaintsColumnChart from "@/components/Reports/Charts/ComplaintsColumnChart.vue";
import InstructorReportView from "@/components/Reports/InstructorReportView.vue";
import FinancialReportView from "@/components/Reports/FinancialReportView.vue";
import {
  LOAD_MANAGEMENT_REPORT,
  M_OU,
  LOAD_FEEDBACK_REPORT,
  LOAD_COMPLAINTS_REPORT,
  LOAD_INSTUCTOR_REPORTS,
  LOAD_FINANCIAL_REPORTS,
  M_COMPUTE,
  PROCESS_AGE_RATIOS,
  PROCESS_GENDER_RATIOS,
  PROCESS_GEAR_RATIOS,
  PROCESS_GEO_RATIOS,
} from "@/store/mutation-list";
import { mapActions } from "vuex";
export default {
  name: "ResultReport",
  components: {
    // DateRangePicker,
    FrequencyTable,
    ColumnChart,
    ProgressBars,
    ComplaintsReportView,
    // ComplaintsColumnChart,
    GeneralLineChart,
    InstructorReportView,
    FinancialReportView,
  },
  data: function () {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Financial & Management Reports",
          disabled: false,
          href: "/dashboard/managementreports",
        },
        {
          text: this.$route.params.obj.repName.text,
          disabled: true,
          href: "/dashboard/managementreports/viewreport",
        },
      ],
      repselectFin: null,
      repselectMan: null,
      repselectFeedB: null,
      repselectComp: null,
      repselectADI: null,
      selectedReport: "",
      selectedRepType: "",
      reportingTypes: [
        { id: 1, text: "Financial Reports", disabled: true },
        { id: 2, text: "Management Reports", disabled: false },
        { id: 3, text: "Feedback Reports", disabled: false },
        { id: 4, text: "Complaint Reports", disabled: false },
        { id: 5, text: "Instructor Reports", disabled: false },
      ],

      showTable: false,
      tableData: null,
      showRatingsProgress: false,
      ratingsData: null,
      list1: 0,
      showComplaintsTable: false,
      complaintsData: null,
      //addtional data
      compalintStatus: null,
      outpcData: null,
      allCompData: [], //this contains all the data for complaint sections
      //adi reports
      showADIVehicleTable: false,
      adiVehicleData: null,
      dialog: false, //the date picking dialog
      criticalError: false, // this error if params empty
      dataError: false, // just no data to display

      //financial reports
      showFinancialTables: false,
      financialTableData: null,

      //line chart data
      lineChartAgeData: [],
      lineChartGenderData: [],
      lineChartGearData: [],
      lineChartGeoData: [],
    };
  },
  methods: {
    ...mapActions({
      requestReport: `${M_OU}${LOAD_MANAGEMENT_REPORT}`,
      requestFeedbackReport: `${M_OU}${LOAD_FEEDBACK_REPORT}`,
      requestComplaintsReport: `${M_OU}${LOAD_COMPLAINTS_REPORT}`,
      requestInstructorReport: `${M_OU}${LOAD_INSTUCTOR_REPORTS}`,
      requestFinancialReport: `${M_OU}${LOAD_FINANCIAL_REPORTS}`,
      processAge: `${M_COMPUTE}${PROCESS_AGE_RATIOS}`,
      processGender: `${M_COMPUTE}${PROCESS_GENDER_RATIOS}`,
      processGear: `${M_COMPUTE}${PROCESS_GEAR_RATIOS}`,
      processGeo: `${M_COMPUTE}${PROCESS_GEO_RATIOS}`,
    }),
    //this method to select which report is bean selected
    clikcedRep(repCat, selrep) {
      this.dialog = true;
      switch (repCat) {
        case 1:
          this.repselectMan = null;
          this.repselectFeedB = null;
          this.repselectComp = null;
          this.repselectADI = null;
          this.selectedRepType = 1;
          break;
        case 2:
          //this.repselectMan = null;
          this.repselectFeedB = null;
          this.repselectComp = null;
          this.repselectADI = null;
          this.selectedRepType = 2;
          break;
        case 3:
          this.repselectMan = null;
          //this.repselectFeedB = null;
          this.repselectComp = null;
          this.repselectADI = null;
          this.selectedRepType = 3;
          break;
        case 4:
          this.repselectMan = null;
          this.repselectFeedB = null;
          //this.repselectComp = null;
          this.repselectADI = null;
          this.selectedRepType = 4;
          break;
        case 5:
          this.repselectMan = null;
          this.repselectFeedB = null;
          this.repselectComp = null;
          //this.repselectADI = null;
          this.selectedRepType = 5;
          break;

        default:
          break;
      }
      this.clearSubReport();
      //this.selectedReport = null;
      this.selectedReport = selrep;
    },
    genReport(val) {
      //console.log("Generete report");
      //console.log(val.data.e);
      //console.log(val.data.s);
      //doing the request

      if (this.selectedRepType == 1) {
        this.requestFinancialReport({
          reportNo: this.selectedReport,
          start: val.data.s,
          end: val.data.e,
        })
          .then((repData) => {
            if (repData != "ERROR") {
              //console.log(repData);
              this.financialTableData = repData;
              this.showFinancialTables = true;
            } else {
              this.dataError = true;
              console.log("Error Issue with data");
              this.financialTableData = [];
              this.showFinancialTables = true;
            }
          })
          .catch((e) => {
            console.log("Error Occered" + e);
          });
      } else if (this.selectedRepType == 2) {
        this.requestReport({
          reportNo: this.selectedReport,
          start: val.data.s,
          end: val.data.e,
        })
          .then((repData) => {
            if (repData != "ERROR") {
              this.tableData = repData;
              this.showTable = true;
              console.log("Called the function");
              this.getChartFormatData(1, repData);
              this.getChartFormatData(2, repData);
              this.getChartFormatData(3, repData);
              this.getChartFormatData(4, repData);
            } else {
              this.dataError = true;
              console.log("Error Issue with data");
              this.tableData = [];
              this.showTable = true;
            }
          })
          .catch((e) => {
            console.log("Error Occered" + e);
          });
      } else if (this.selectedRepType == 3) {
        this.requestFeedbackReport({
          reportNo: this.selectedReport,
          start: val.data.s,
          end: val.data.e,
        })
          .then((repData) => {
            console.log(repData);
            if (repData != "ERROR") {
              this.ratingsData = repData;
              this.showRatingsProgress = true;
            } else {
              console.log("Error Issue with data");
              this.dataError = true;
              this.ratingsData = [];
              this.showRatingsProgress = true;
            }
          })
          .catch((e) => {
            console.log("Error Occered" + e);
          });
      } else if (this.selectedRepType == 4) {
        this.requestComplaintsReport({
          reportNo: this.selectedReport,
          start: val.data.s,
          end: val.data.e,
        })
          .then((repData) => {
            if (repData != "ERROR") {
              this.allCompData = repData;
              //rest needs to be commnected out
              this.complaintsData = repData.report_data;
              /*if (Object.hasOwn(repData, "status_data")) {
                this.compalintStatus = repData.status_data;
              }
              if (Object.hasOwn(repData, "outerpc_data")) {
                this.outpcData = repData.outerpc_data;
              }*/
              this.showComplaintsTable = true;
            } else {
              console.log("Error Issue with data");
              this.dataError = true;
              this.complaintsData = [];
              this.showComplaintsTable = true;
            }
          })
          .catch((e) => {
            console.log("Error Occered" + e);
          });
      } else if (this.selectedRepType == 5) {
        //this will different request for adi
        this.requestInstructorReport({
          reportNo: this.selectedReport,
          start: val.data.s,
          end: val.data.e,
        })
          .then((repData) => {
            if (repData != "ERROR") {
              this.adiVehicleData = repData;
              this.showADIVehicleTable = true;
            } else {
              console.log("Error Issue with data");
              this.dataError = true;
              this.adiVehicleData = [];
              this.showADIVehicleTable = true;
            }
          })
          .catch((e) => {
            console.log("Error Occered" + e);
          });
      }
    },
    //this method will create arrays based on array keynames
    getChartFormatData(reptype, repData) {
      //type 1 -> gender based reports
      //type 2 -> age based reports
      //type 3 ->  gear based reports
      //type 4 ->  postcode based reports
      let retArr = [];
      switch (reptype) {
        case 1:
          this.processAge(repData).then((replay) => {
            this.lineChartAgeData = replay;
          });
          break;
        case 2:
          this.processGender(repData).then((replay) => {
            this.lineChartGenderData = replay;
          });

          break;
        case 3:
          this.processGear(repData).then((replay) => {
            this.lineChartGearData = replay;
          });
          break;
        case 4:
          this.processGeo(repData).then((replay) => {
            this.lineChartGeoData = replay;
          });
          break;
        default:
          break;
      }
      if (!reptype && !this.tableData) {
        return []; //nothing to report
      } else {
        return retArr;
      }
    },
    clearSubReport() {
      this.showTable = null;
      this.tableData = null;
      this.showRatingsProgress = null;
      this.complaintsData = null;
      this.compalintStatus = null;
      this.adiVehicleData = null;
    },
    clearMainReport() {
      this.clearSubReport();
      this.selectedReport = null;
    },
  },
  created() {
    //console.log(this.$route.params.obj);
    if (this.$route.params.obj) {
      this.criticalError = false; // just to clear error message
      let inObj = this.$route.params.obj;
      this.selectedRepType = inObj.selRepType;
      this.selectedReport = inObj.reportNo;

      this.genReport({ data: { s: inObj.start, e: inObj.end } });
    } else {
      //missing data error
      // show alert
      this.criticalError = true;
    }
  },
};
</script>

<style></style>
