<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Chart</h3>
      <GChart
        type="LineChart"
        :settings="{ packages: ['corechart'] }"
        :data="genGChart"
        :options="chartOptions"
        :title="chartOptions.chart.title"
      />
    </div>
  </v-col>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "FeedbackLineChart",
  components: { GChart },
  props: ["chartTitle", "chartSubTitle", "dataTitles", "dataVals"],
  data() {
    return {
      chartOptions: {
        chart: {
          title: this.chartTitle,
          subtitle: this.chartSubTitle,
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        // isStacked: "percent",
        height: 600,
        curveType: "function",
        animation: {
          startup: true,
          duration: 2000,
          easing: "out",
        },
        // vAxis: { minValue: 1 },
        is3D: true,
        lineWidth: 3,
        pointSize: 8,
        //pointShape: "star",
      },
    };
  },
  methods: {
    getTableKeys(arr) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key = JSON.stringify(rec.key).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
  },
  computed: {
    genGChart() {
      //this will generate the whole line chart
      let retar = [];
      if (this.dataTitles && this.dataVals) {
        //there are some data with titles and vales
        retar.push(this.dataTitles);
        this.dataVals.forEach((rec) => {
          let row = [];
          for (let i = 0; i < rec.length; i++) {
            //add all the elements to an array
            row.push(rec[i]);
          }
          //add the array as a new record array to main array
          //["title","title","title"],["val","val","val"]
          retar.push(row);
        });
      }

      /*if (this.compData) {
        retar.push(["Outer Postcode", "Covering ADIs", "Complaints"]);
        this.pcData.forEach((rec) => {
          let obj = this.compData.find((comp) => comp.key === rec.key);
          //there is a match
          if (obj) {
            //lets add that to array
            retar.push([rec.key, rec.count, obj.count]);
          } else {
            //ther is no match so add zero
            retar.push([rec.key, rec.count, 0]);
          }
        });
      } else {
        retar.push(["Outer Postcode", "Covering ADIs"]);
        this.pcData.forEach((rec) => {
          retar.push([rec.key, rec.count]);
        });
      }*/

      return retar;
    },
  },
};
</script>
