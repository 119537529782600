<template>
  <v-row justify="center" class="text-center mt-5">
    <TodaysTasks />
    <v-col
      v-for="(item, index) in panels"
      :key="index"
      cols="12"
      sm="6"
      md="4"
      lg="4"
      xl="4"
    >
      <v-card class="pa-1 text-center" outlined tile elevation="2">
        <v-card-title class="grey lighten-4" style="flex-direction: column">
          <span class="text-h6 text-center">{{ item.title }}</span>
        </v-card-title>
        <v-img
          :src="getImgUrl(item.img)"
          height="250px"
          contain
          class="ma-2"
        ></v-img>
        <!--PDF Links  -->
        <template v-if="item.type == 'docs'">
          <v-list v-for="(func, i) in item.targets" :key="i" class="pa-0">
            <v-divider></v-divider>
            <v-list-item
              link
              @click="showPDF(i)"
              :disabled="!can(func.privilege)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ func.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <!-- normal links -->
        <template v-else>
          <v-list v-for="(func, i) in item.targets" :key="i" class="pa-0">
            <v-divider></v-divider>
            <v-list-item link :to="func.link" :disabled="!can(func.privilege)">
              <v-list-item-content>
                <v-list-item-title>
                  <v-badge
                    v-if="func.tasks"
                    :content="func.num"
                    :value="func.num"
                    color="amber darken-4"
                    inline
                    style="margin-top: -16px"
                    ><span>{{ func.name }}</span></v-badge
                  >
                  <span v-else>{{ func.name }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-card>
    </v-col>
    <!-- PDF Views -->
    <PDFViewer
      :dialog="pdfA"
      @close="pdfA = false"
      title="Learner's Terms &amp; Conditions"
      url="https://drive.brandiie.com/ctrl/terms_and_conditions.php?tac=1"
    />
    <PDFViewer
      :dialog="pdfB"
      @close="pdfB = false"
      title="Instructor's Terms &amp; Conditions"
      url="https://drive.brandiie.com/ctrl/terms_and_conditions.php?tac=2"
    />
    <PDFViewer
      :dialog="pdfC"
      @close="pdfC = false"
      title="Driving Instructor Training Programme"
      url="https://drive.brandiie.com/ctrl/terms_and_conditions.php?tac=3"
    />
    <PDFViewer
      :dialog="pdfD"
      @close="pdfD = false"
      title="Policies, GDPR, Cookies and Website T&amp;Cs"
      url="https://drive.brandiie.com/ctrl/terms_and_conditions.php?tac=4"
    />
  </v-row>
</template>

<script>
import PDFViewer from "@/components/Popups/PDFViewer";
import TodaysTasks from "@/components/TodaysTasks";
export default {
  name: "DashPanels",
  components: { PDFViewer, TodaysTasks },
  data: () => ({
    panels: [
      /*{
        title: "Today's Tasks",
        img: "tasks.webp",
        targets: [
          {
            name: "Active Complaints",
            link: "/dashboard/viewactivecomplaints",
            privilege: "user",
            tasks: true,
            num: 5,
          },
          {
            name: "View Pending Instructors",
            link: "/dashboard/pendinginstructors",
            privilege: "user",
            tasks: true,
            num: 0,
          },
          {
            name: "Assign Learners",
            link: "/dashboard/assigninstructor",
            privilege: "user",
            tasks: true,
            num: 1,
          },
          {
            name: "Pending Bookings",
            link: "/dashboard/bookpendinglearners",
            privilege: "user",
            tasks: true,
            num: 25,
          },
        ],
      },*/
      {
        title: "Learners",
        img: "learner.webp",
        targets: [
          {
            name: "Register Learner",
            link: "/dashboard/registerlearner",
            privilege: "user",
          },
          {
            name: "View/Edit Learners",
            link: "/dashboard/managelearners",
            privilege: "user",
          },
          {
            name: "Accept Payments",
            link: "/dashboard/acceptpayments",
            privilege: "user",
          },
          {
            name: "Assign Instructor",
            link: "/dashboard/assigninstructor",
            privilege: "user",
          },
        ],
      },

      {
        title: "Instructors",
        img: "instructor.webp",
        targets: [
          {
            name: "Register Instructor",
            link: "/dashboard/registerinstructor",
            privilege: "user",
          },
          {
            name: "View/Edit Instructors",
            link: "/dashboard/vieweditinstructors",
            privilege: "user",
          },
          {
            name: "View Pending Instructors",
            link: "/dashboard/pendinginstructors",
            privilege: "user",
          },
          {
            name: "Advance Instructor Search",
            link: "/dashboard/searchinstructor",
            privilege: "user",
          },
        ],
      },
      {
        title: "Bookings",
        img: "cal.png",
        targets: [
          {
            name: "Book Learners",
            link: "/dashboard/booklearners",
            privilege: "user",
          },
          {
            name: "Book Pending Bookings",
            link: "/dashboard/bookpendinglearners",
            privilege: "user",
          },
          {
            name: "View Instructor Diary",
            link: "/dashboard/instructordiary",
            privilege: "user",
          },
          {
            name: "Record Driving Test Outcome",
            link: "/dashboard/recordtestoutcome",
            privilege: "user",
          },
        ],
      },
      {
        title: "Feedback",
        img: "feedback2.webp",
        targets: [
          {
            name: "Record Learner Feedbacks",
            link: "/dashboard/recordlearnerfeedbacks",
            privilege: "user",
          },
          {
            name: "View Learner Feedbacks",
            link: "/dashboard/viewlearnerfeedback",
            privilege: "user",
          },
          {
            name: "Record Instructor Feedbacks",
            link: "/dashboard/recordinstructorfeedbacks",
            privilege: "user",
          },
          {
            name: "View Instructor Feedbacks",
            link: "/dashboard/viewinstructorfeedback",
            privilege: "user",
          },
        ],
      },
      {
        title: "Complaints",
        img: "compv2.webp",
        targets: [
          {
            name: "Record Complaint",
            link: "/dashboard/recordcomplaint",
            privilege: "user",
          },
          {
            name: "Active Complaints",
            link: "/dashboard/viewactivecomplaints",
            privilege: "user",
          },
          {
            name: "Resolved Complaints",
            link: "/dashboard/resolvedcomplaints",
            privilege: "user",
          },
          { name: "", link: "" },
        ],
      },
      {
        title: "Management Reports",
        img: "report.webp",
        targets: [
          /*{
            name: "Learners' Payment Data",
            link: "/dashboard/learnerpaymentdata",
            privilege: "user",
          },*/
          {
            name: "Learners' Monthly Overview",
            link: "/dashboard/learnermonthlyoverview",
            privilege: "user",
          },
          {
            name: "Instructors' Monthly Overview",
            link: "/dashboard/instructormonthlyoverview",
            privilege: "user",
          },
          /*{
            name: "Instructors' Performance Reports",
            link: "/dashboard/instructorperformancereport",
            privilege: "user",
          },*/
          {
            name: "Instructors' Franchise Payment Reports",
            link: "/dashboard/instructorfranchisepaydata",
            privilege: "user",
          },
          {
            name: "Financial & Management Reports",
            link: "/dashboard/managementreports",
            privilege: "reports",
          },
        ],
      },
      {
        title: "Terms & Conditions",
        img: "docs.webp",
        targets: [
          { name: "Learner's Terms & Conditions", link: "", privilege: "user" },
          {
            name: "Instructor's Terms & Conditions",
            link: "",
            privilege: "user",
          },
          {
            name: "Driving Instructor Training Programme",
            link: "",
            privilege: "user",
          },
          {
            name: "Policies, GDPR, Cookies and Website T&Cs",
            link: "",
            privilege: "user",
          },
        ],
        type: "docs",
      },
      {
        title: "Payments & Transactions",
        img: "money.webp",
        targets: [
          {
            name: "Record Instructor Franchise Payments",
            link: "/dashboard/recordinstructorfranchisepayments",
            privilege: "user",
          },
          {
            name: "Accept Learner Payments",
            link: "/dashboard/acceptpayments",
            privilege: "user",
          },
          {
            name: "Accept Instructor Payments",
            link: "/dashboard/acceptinstructorpayments",
            privilege: "user",
          },
          { name: "", link: "", privilege: "user" },
        ],
      },
    ],
    pdfA: false,
    pdfB: false,
    pdfC: false,
    pdfD: false,
  }),
  methods: {
    getImgUrl(pic) {
      return require("../assets/img/dash/" + pic);
    },
    showPDF(num) {
      //num is the order use a switch case
      switch (num) {
        case 0:
          this.pdfA = true;
          break;
        case 1:
          this.pdfB = true;
          break;
        case 2:
          this.pdfC = true;
          break;
        case 3:
          this.pdfD = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
