<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Select Learner</h2>
        <v-data-table
          :headers="headers"
          :items="recentLearners"
          :search="keyword"
          class="elevation-1"
          @click:row="openDialog"
        >
          <template v-slot:top>
            <v-text-field
              v-model="keyword"
              label="Search"
              placeholder="Enter keyword to search"
              class="ma-4"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>

            <LearnerOverviewDialog
              :dialog="dialog"
              :learnerobj="passingLeanerForDash"
              @closedialog="dialog = false"
            />
          </template>

          <template v-slot:[`item.student_id`]="{ item }">
            <strong>{{ "L" + item.student_id }}</strong>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.title + " " + item.fname + " " + item.lname }}
          </template>
          <template v-slot:[`item.mobile`]="{ item }">
            {{ item.mobile | mobileno }}
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
//import LearnerEyeTest from "@/components/LearnerComps/LearnerEyeTest.vue";
import LearnerOverviewDialog from "@/components/Reports/MonthlyOverview/LearnerOverviewDialog.vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_RECENT_LEARNERS,
  M_OU,
  LOAD_RECENT_LEARNERS,
  // LOAD_ASSINGED_LEARERS,
  // GET_ASSINGED_LEARERS,
} from "@/store/mutation-list";
export default {
  name: "LearnerMonthlyOverview",
  components: {
    LearnerOverviewDialog,

    //LearnerEyeTest
  },
  data: () => {
    return {
      frmType: "edit",
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Learners' Monthly Overview",
          disabled: true,
          href: "/dashboard/learnermonthlyoverview",
        },
      ],
      dialog: false,
      passingID: null,
      passingLeanerForDash: {},
      //data table realated data
      keyword: "",
      headers: [
        { text: "Learner ID", value: "student_id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
        { text: "Pickup Postcode", value: "pickup_pc" },
        { text: "Date Joined", value: "created" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      recentLearners: `${M_OU}${GET_RECENT_LEARNERS}`,
    }),
    //this method returns active ADI or last adi if available
  },
  methods: {
    ...mapActions({
      loadLearners: `${M_OU}${LOAD_RECENT_LEARNERS}`,
      //loadAssignLearners: `${M_OU}${LOAD_ASSINGED_LEARERS}`,
    }),

    openDialog(learner) {
      //console.log(learner);
      this.passingID = learner.student_id;
      this.passingLeanerForDash = learner;
      //do all the work then
      this.dialog = true;
      //fill the current learner ADI history
    },
    close() {
      //remove data object

      this.dialog = false;
      this.passingID = null;
      this.passingLeanerForDash = {};
    },
  },
  created() {
    this.loadLearners();
    // this.loadAssignLearners();
  },
};
</script>

<style></style>
