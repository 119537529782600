<template>
  <v-dialog v-model="dialgOn" max-width="860px" scrollable persistent>
    <v-card>
      <v-card-title class="text-h5 blue lighten-2">
        <span>Instructor Monthly Overview</span>
      </v-card-title>
      <v-card-text>
        <v-simple-table v-if="Object.keys(adiobj).length > 0">
          <template v-slot:default>
            <tbody>
              <tr>
                <th class="text-left">Instructor ID</th>
                <td>A{{ adiobj.adi_id }}</td>
                <th class="text-left">Name</th>
                <td>
                  {{ adiobj.title }} {{ adiobj.fname }}
                  {{ adiobj.lname }}
                </td>
                <th class="text-left">Mobile</th>
                <td>{{ adiobj.mobile | mobileno }}</td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td>{{ adiobj.email }}</td>
                <th class="text-left">Gear Type</th>
                <td style="text-transform: capitalize">
                  {{ adiobj.teaching_gears }}
                </td>
                <th class="text-left">Joined</th>
                <td>{{ adiobj.created }}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td colspan="5">
                  {{
                    [
                      adiobj.currAddr.fline,
                      adiobj.currAddr.sline,
                      adiobj.currAddr.city,
                      adiobj.currAddr.county,
                      adiobj.currAddr.postcode,
                    ] | oneLineAddress
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-alert v-else dense outlined type="error" class="mt-5">
          No Learner data to display.
        </v-alert>
      </v-card-text>
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab href="#tab-1">Monthly Overview</v-tab>
        <v-tab href="#tab-2">E-mail History</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text class="pa-5">
              <div class="mx-10">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left">Report</th>
                      <th colspan="3" class="text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="ovPDFHistory.length > 0">
                      <tr v-for="item in ovPDFHistory" :key="item.pdf_id">
                        <td>
                          {{ item.month_name }}
                          {{ item.reporting_year }}
                        </td>
                        <td>
                          <v-btn
                            outlined
                            color="info"
                            @click="
                              viewFile(
                                item.reporting_year,
                                item.reporting_month
                              )
                            "
                            ><v-icon left> mdi-eye-outline </v-icon> View</v-btn
                          >
                        </td>
                        <td>
                          <v-btn
                            outlined
                            color="info"
                            @click="sendEmail(item.pdf_id)"
                          >
                            <v-icon left> mdi-email-fast-outline </v-icon>
                            E-mail</v-btn
                          >
                        </td>
                        <td>
                          <v-btn
                            outlined
                            color="info"
                            @click="
                              downloadFile(
                                item.reporting_year,
                                item.reporting_month
                              )
                            "
                          >
                            <v-icon left> mdi-tray-arrow-down</v-icon>
                            Download</v-btn
                          >
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <th colspan="4" class="text-center">
                          <v-alert dense outlined type="info" class="mt-5">
                            No data to display
                          </v-alert>
                        </th>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <div class="mx-10">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left">Report</th>
                      <th class="text-left">E-mail Address</th>
                      <th class="text-left">E-mailed By</th>
                      <th class="text-left">E-mailed On</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="emailHistory.length > 0">
                      <tr v-for="item in emailHistory" :key="item.record_id">
                        <td>{{ item.month_name }} {{ item.reporting_year }}</td>
                        <td>{{ item.email_address }}</td>
                        <td>{{ item.sent_by }}</td>
                        <td>{{ item.sent_on | dateReOrder }}</td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <th colspan="4" class="text-center">
                          <v-alert dense outlined type="info" class="mt-5">
                            No data to display
                          </v-alert>
                        </th>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-card-text>
        <v-container>
          <!-- <v-divider></v-divider> -->
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  M_OU,
  LOAD_INSTRUCTOR_OVERVIEW_SENT_EMAILS,
  LOAD_INSTRUCTOR_OVERVIEW_GENERATED_PDFS,
  DOWNLOAD_OVERVIEW_PDF,
  SEND_INSTRUCTOR_OVERVIEW_PDF,
} from "@/store/mutation-list";
import { mapActions } from "vuex/dist/vuex.common.js";

export default {
  name: "InstructorOverviewDialog",
  props: ["dialog", "adiobj"],
  data() {
    return {
      tab: null,
      icon: "justify",
      isEmailHistoryLoading: false,
      isEmailHistory: false,
      emailHistory: [],

      isOvPDFHistoryLoading: false,
      isOvPDFHistory: false,
      ovPDFHistory: [],
    };
  },

  computed: {
    ...mapGetters({
      //UpdateStatusList: `${M_DD}${GET_COMPLAINT_STATUS_LIST}`,
    }),
    dialgOn() {
      if (this.dialog) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getEmailHistory: `${M_OU}${LOAD_INSTRUCTOR_OVERVIEW_SENT_EMAILS}`,
      getOverviewPDFHistory: `${M_OU}${LOAD_INSTRUCTOR_OVERVIEW_GENERATED_PDFS}`,
      downloadPDF: `${M_OU}${DOWNLOAD_OVERVIEW_PDF}`,
      reSendEmail: `${M_OU}${SEND_INSTRUCTOR_OVERVIEW_PDF}`,
    }),
    closeDialog() {
      this.emailHistory = [];
      this.isEmailHistory = false;

      this.ovPDFHistory = [];
      this.isOvPDFHistory = false;

      this.$emit("closedialog");
    },
    loadEmailHistory(passingID) {
      //console.log("Called");
      this.isEmailHistoryLoading = true;
      this.getEmailHistory({ adi: passingID })
        .then((rep) => {
          //lets check if we get an empty arrya
          //console.log(rep);
          if (rep.length > 0) {
            this.isEmailHistory = true;
            this.emailHistory = []; //rep;
            rep.forEach((rec) => {
              this.emailHistory.push(rec);
            });
          } else {
            //console.log("Nothing inside");
            this.isEmailHistory = false;
            this.emailHistory = [];
          }
        })
        .finally(() => {
          this.isEmailHistoryLoading = false;
        });
    },
    loadPDFs(passingID) {
      //LOAD_LEARNER_OVERVIEW_GENERATED_PDFS
      console.log("Called");
      this.isOvPDFHistoryLoading = true;
      this.getOverviewPDFHistory({ adi: passingID })
        .then((rep) => {
          //lets check if we get an empty arrya
          //console.log(rep);
          if (rep.length > 0) {
            this.isOvPDFHistory = true;
            this.ovPDFHistory = []; //rep;
            rep.forEach((rec) => {
              this.ovPDFHistory.push(rec);
            });
          } else {
            //console.log("Nothing inside");
            this.isOvPDFHistory = false;
            this.ovPDFHistory = [];
          }
        })
        .finally(() => {
          this.isOvPDFHistoryLoading = false;
        });
    },
    downloadFile(rep_year, rep_month) {
      let fname = `A${this.adiobj.adi_id} Overview Report for ${rep_month}-${rep_year}.pdf`;
      this.downloadPDF({
        type: 2, //for learner 2 for instructor
        year: rep_year,
        month: rep_month,
        id: this.adiobj.adi_id,
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fname);
        document.body.appendChild(link);
        link.click();
      });
    },
    viewFile(rep_year, rep_month) {
      let fname = `A${this.adiobj.adi_id} Overview Report for ${rep_month}-${rep_year}.pdf`;
      this.downloadPDF({
        dw: "true", //add this to make file viewable
        type: 2, //for learner 2 for instructor
        year: rep_year,
        month: rep_month,
        id: this.adiobj.adi_id,
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fname);
        document.body.appendChild(link);
        link.click();
      });
    },
    sendEmail(pdf_id) {
      if (pdf_id) {
        this.$fire({
          title: "Do you want to send the monthy overview via email?",
          text: `Email will sent to ${this.adiobj.email}`,
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Send",
          // denyButtonText: `Don't save`,
          // icon: "question",
          type: "question",
          //timer: 3000,
          allowOutsideClick: false,
        }).then((r) => {
          if (r.value) {
            //lets send the email from here
            this.reSendEmail({ id: pdf_id }).then((rep) => {
              if (rep > 0) this.loadEmailHistory(this.adiobj.adi_id); //success loadn the info again
            });
            this.$fire({ title: "E-mail Sent!", type: "success" });
          }
        });
      }
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    adiobj: function (newObj, _oldObj) {
      //console.log(newObj);
      //console.log(oldObj);
      if ("adi_id" in newObj) {
        //call the load
        this.loadEmailHistory(this.adiobj.adi_id);
        this.loadPDFs(this.adiobj.adi_id);
      }
    },
  },
};
</script>

<style></style>
