import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { auth as M_AUTH } from "./modules/auth";
import { dd as M_DD } from "./modules/dd";
import { officeUser as M_OU } from "./modules/officeuser";
import { officeAdmin as M_OA } from "./modules/officeadmin";
import { sharedsync as M_COMMON } from "./modules/sharedsync";
import { adiEdits as M_ADI } from "./modules/adiedits";
import { process as M_COMPUTE } from "./modules/process";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { M_AUTH, M_DD, M_OU, M_OA, M_COMMON, M_ADI, M_COMPUTE },
  plugins: [createPersistedState()],
});
