import {
  PROCESS_AGE_RATIOS,
  PROCESS_GENDER_RATIOS,
  PROCESS_GEAR_RATIOS,
  PROCESS_GEO_RATIOS,
} from "@/store/mutation-list";
//import { M_DD } from "../mutation-list";

export const process = {
  namespaced: true,
  state: {},

  actions: {
    // eslint-disable-next-line no-unused-vars
    [PROCESS_AGE_RATIOS]({ commit }, payload) {
      //console.log("printing from store");

      if (payload) {
        //do the process with payload
        let A = 0,
          B = 0,
          C = 0,
          D = 0,
          E = 0,
          F = 0;
        if (payload) {
          payload.forEach((rec) => {
            switch (rec.age_ratio) {
              case "A":
                A++;
                break;
              case "B":
                B++;
                break;
              case "C":
                C++;
                break;
              case "D":
                D++;
                break;
              case "E":
                E++;
                break;
              case "F":
                F++;
                break;

              default:
                break;
            }
          });
        }

        let row = [];
        row.push(["17 - 25", A]);
        row.push(["26 - 35", B]);
        row.push(["36 - 45", C]);
        row.push(["46 - 55", D]);
        row.push(["56 - 65", E]);
        row.push(["65 & over", F]);

        row.unshift(["Age Group", "Total"]);

        //console.log("printing output:");
        //console.log(row);

        return row;
      }
      return [];
    },
    // eslint-disable-next-line no-unused-vars
    [PROCESS_GENDER_RATIOS]({ commit }, payload) {
      //console.log("printing from store");

      if (payload) {
        //do the process with payload
        let Male = 0,
          Female = 0;
        if (payload) {
          payload.forEach((rec) => {
            switch (rec.gender) {
              case "m":
                Male++;
                break;
              case "f":
                Female++;
                break;

              default:
                break;
            }
          });
        }

        let row = [];
        row.push(["Female", Female]);
        row.push(["Male", Male]);

        row.unshift(["Genders", "Total"]);

        return row;
      }
      return [];
    },
    // eslint-disable-next-line no-unused-vars
    [PROCESS_GEAR_RATIOS]({ commit }, payload) {
      //console.log("printing from store");

      if (payload) {
        //do the process with payload
        let a = 0,
          m = 0;
        if (payload) {
          payload.forEach((rec) => {
            switch (rec.lesson_type) {
              case "manual":
                m++;
                break;
              case "automatic":
                a++;
                break;

              default:
                break;
            }
          });
        }

        let row = [];
        row.push(["Automatic", a]);
        row.push(["Manual", m]);

        row.unshift(["Gear Types", "Total"]);

        return row;
      }
      return [];
    },
    // eslint-disable-next-line no-unused-vars
    [PROCESS_GEO_RATIOS]({ commit }, payload) {
      //console.log("printing from store");

      if (payload) {
        let counter = {};
        payload.forEach(function (rec) {
          var key = JSON.stringify(rec.outer_pc).replaceAll('"', "");
          //console.log(key);
          counter[key] = (counter[key] || 0) + 1;
        });
        let tempMap = new Map(Object.entries(counter));
        console.log("Converted to Map");
        console.log(tempMap);
        console.log("Converted to array");
        console.log(Array.from(tempMap));
        //return Array.from(tempMap);

        let row = Array.from(tempMap);

        row.unshift(["Geographical Area", "Total"]);

        return row;
      }
      return [];
    },
  },
};
