<template>
  <v-app-bar app :color="NavColour" short>
    <!-- <v-row justify="space-around"> -->
    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          to="/dashboard/home"
          color="blue darken-4"
        >
          <v-icon>mdi-view-dashboard-outline </v-icon></v-btn
        >
      </template>
      <span>Dashboard</span>
    </v-tooltip>

    <v-toolbar-title
      ><v-img
        class="ma-2"
        alt="logo"
        src="../assets/img/__becodrivelogo.svg"
        aspect-ratio="4/1"
        width="135"
      ></v-img>
      <!-- {{ bussinessName }} -->
    </v-toolbar-title>
    <v-progress-linear
      :active="bgSync"
      :indeterminate="bgSync"
      absolute
      bottom
      color="lime"
      striped
      height="5"
    ></v-progress-linear>

    <!-- <v-spacer></v-spacer> -->
    <v-menu
      rounded="b-xl"
      open-on-hover
      offset-x
      offset-y
      v-if="!admin"
      transition="fab-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon dark v-bind="attrs" v-on="on" color="blue darken-4">
          <v-badge
            :content="totNotifications"
            :value="totNotifications"
            color="red darken-1"
            top
            right
            overlap
            ><v-icon v-if="!isLoading">mdi-bell</v-icon>
            <v-progress-circular v-if="isLoading" indeterminate color="red">
            </v-progress-circular
          ></v-badge>
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader
          >You have {{ totNotifications | plurelNotefication }}
        </v-subheader>
        <v-divider></v-divider>
        <v-list-item to="/dashboard/viewactivecomplaints"
          ><v-btn icon>
            <v-badge
              :content="actCompTot"
              :value="actCompTot"
              color="red darken-2"
              left
              bottom
              overlap
            >
              <v-icon>mdi-emoticon-sad-outline</v-icon>
            </v-badge>
          </v-btn>
          <v-list-item-content>
            <v-list-item-title>Active Complaints</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item to="/dashboard/pendinginstructors"
          ><v-btn icon>
            <v-badge
              :content="pendAdiTot"
              :value="pendAdiTot"
              color="red darken-2"
              left
              bottom
              overlap
            >
              <v-icon>mdi-account-alert-outline </v-icon>
            </v-badge>
          </v-btn>
          <v-list-item-content>
            <v-list-item-title>Pending Instructors</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item to="/dashboard/assigninstructor"
          ><v-btn icon>
            <v-badge
              :content="assignLearTot"
              :value="assignLearTot"
              color="red darken-2"
              left
              bottom
              overlap
            >
              <v-icon>mdi-account-arrow-right-outline</v-icon>
            </v-badge>
          </v-btn>
          <v-list-item-content>
            <v-list-item-title>Assign Learners</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item to="/dashboard/bookpendinglearners"
          ><v-btn icon>
            <v-badge
              :content="pendBookTot"
              :value="pendBookTot"
              color="red darken-2"
              left
              bottom
              overlap
            >
              <v-icon>mdi-book-clock-outline</v-icon>
            </v-badge>
          </v-btn>
          <v-list-item-content>
            <v-list-item-title>Pending Bookings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- 
        <v-divider></v-divider>
        <v-list-item></v-list-item> -->
      </v-list>
    </v-menu>

    <v-spacer></v-spacer>
    <span
      ><strong>Enterprise Suite {{ ver }} UK Edition</strong
      >&nbsp;&nbsp;&nbsp;<em>Powered With AI</em></span
    >
    <v-spacer></v-spacer>
    <!-- <v-btn icon v-if="!admin"> <v-icon>mdi-wallet-giftcard</v-icon></v-btn> -->
    <Packages v-if="!admin" />
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          color="blue darken-4"
          href="/ctrl/help.pdf"
          target="_blank"
        >
          <v-icon>mdi-help-circle-outline </v-icon></v-btn
        >
      </template>
      <span>Help Guide</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="logout"
          to="/"
          color="blue darken-4"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      <span>Log Out</span>
    </v-tooltip>
    <!-- </v-row> -->
  </v-app-bar>
</template>

<script>
import { AppSetup } from "@/store/sitedata";
import {
  GET_BACKGROUND_SYNC,
  LOGOUT,
  M_COMMON,
  M_AUTH,
  LOAD_NOTIFICATION_DATA,
  M_OU,
  IS_NOTIFICATION_LOADING,
  GET_NOTIFICATION_DATA,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
import Packages from "@/components/Popups/Packages.vue";
export default {
  name: "Navbar",
  components: { Packages },
  props: {
    NavColour: String,
    admin: Boolean,
  },
  data: () => ({
    drawer: false,
    group: null,
    show: false,
    // text data
    bussinessName: AppSetup.name,
    //app verison
    ver: AppSetup.version,
  }),
  methods: {
    ...mapActions({
      user_logout: `${M_AUTH}${LOGOUT}`,
      loadNotifications: `${M_OU}${LOAD_NOTIFICATION_DATA}`,
    }),

    logout() {
      this.user_logout().then((res) => {
        if (res == "DONE") {
          //router to home
          this.$router.push("/");
        } else {
          //show error message
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      bgSync: `${M_COMMON}${GET_BACKGROUND_SYNC}`,
      isLoading: `${M_OU}${IS_NOTIFICATION_LOADING}`,
      notfData: `${M_OU}${GET_NOTIFICATION_DATA}`,
    }),
    totNotifications() {
      if (this.notfData) {
        return (
          Number(this.notfData["tot_act_comps"]) +
          Number(this.notfData["tot_pend_adis"]) +
          Number(this.notfData["tot_pend_learners"]) +
          Number(this.notfData["totl_pend_bookings"])
        );
      } else {
        return 0;
      }
    },
    actCompTot() {
      if (this.notfData) {
        return Number(this.notfData["tot_act_comps"]);
      } else {
        return 0;
      }
    },
    pendAdiTot() {
      if (this.notfData) {
        return Number(this.notfData["tot_pend_adis"]);
      } else {
        return 0;
      }
    },
    assignLearTot() {
      if (this.notfData) {
        return Number(this.notfData["tot_pend_learners"]);
      } else {
        return 0;
      }
    },
    pendBookTot() {
      if (this.notfData) {
        return Number(this.notfData["totl_pend_bookings"]);
      } else {
        return 0;
      }
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  created() {
    this.loadNotifications();
  },
};
</script>

<style></style>
