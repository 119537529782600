<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Select Driving Instructor</h2>
        <p class="sub-title">
          Search Instructors by their ID, Name, E-mail or Mobile Numbers
        </p>
        <InstructorTable
          title=""
          sub-title=""
          :dataarray="resultArry"
          :is-loading="!isLoading"
          :is-search="true"
          @choosen="openDialog"
        />
        <InstructorOverviewDialog
          :dialog="dialog"
          :adiobj="passingAIDForDash"
          @closedialog="dialog = false"
        />
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import InstructorTable from "@/components/Instructor/InstructorTable";
import InstructorOverviewDialog from "@/components/Reports/MonthlyOverview/InstructorOverviewDialog";
import {
  GET_ACTIVE_INSTRUCTORS,
  LOAD_ACTIVE_INSTRUCTORS,
  IS_ACTIVE_INSTRUCTORS_LOADED,
  M_OU,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InstructorMonthlyOverview",
  components: {
    InstructorOverviewDialog,
    InstructorTable,
  },
  data: () => {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Instructors' Monthly Overview",
          disabled: true,
          href: "/dashboard/instructormonthlyoverview",
        },
      ],
      adiObj: [],
      dialog: false,
      passingID: null,
      passingAIDForDash: {},
      //data table realated data
      keyword: "",
      headers: [
        { text: "Learner ID", value: "student_id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
        { text: "Pickup Postcode", value: "pickup_pc" },
        { text: "Date Joined", value: "created" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      resultArry: `${M_OU}${GET_ACTIVE_INSTRUCTORS}`,
      isLoading: `${M_OU}${IS_ACTIVE_INSTRUCTORS_LOADED}`,
    }),
    //this method returns active ADI or last adi if available
  },
  methods: {
    ...mapActions({
      loadActiveADIs: `${M_OU}${LOAD_ACTIVE_INSTRUCTORS}`,
    }),

    openDialog(selectedadi) {
      console.log(selectedadi);

      this.passingID = selectedadi.adi_id;
      this.passingAIDForDash = selectedadi.record;
      //do all the work then
      this.dialog = true;
      //fill the current ADIinfo
    },

    close() {
      //remove data object

      this.dialog = false;
      this.passingID = null;
      this.passingAIDForDash = {};
    },
  },
  created() {
    this.loadActiveADIs();
  },
};
</script>

<style></style>
