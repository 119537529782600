<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Line Charts</h3>

      <v-row dense>
        <v-col md="6">
          <GeneralLineChart
            ChartTitle="Income per gender"
            vAxisTitle="total"
            hAxisTitle="Genders"
            :DataArray="getMoneyPerGenderLineGraphArry"
            :ChartHight="600"
          />
        </v-col>
        <v-col md="6">
          <GeneralLineChart
            ChartTitle="Income per postcode"
            vAxisTitle="total"
            hAxisTitle="Postcodes"
            :DataArray="getMoneyPerPostcodeLineGraphArry"
            :ChartHight="600"
          />
        </v-col>
        <v-col md="12">
          <GeneralLineChart
            ChartTitle="Income per postcode and gender"
            vAxisTitle="total"
            hAxisTitle="Postcodes and Gender"
            :DataArray="getMoneyPerPostcodeGenderLineGraphArry"
            :ChartHight="600"
          />
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>
<script>
import GeneralLineChart from "@/components/Reports/Charts/GeneralLineChart.vue";

export default {
  name: "FinacialIncomeGeneratedLineChart",
  components: { GeneralLineChart },
  props: ["tableData"],

  computed: {
    getMoneyPerGenderLineGraphArry() {
      let temp = [];
      if (this.tableData.gender_totals) {
        this.tableData.gender_totals.forEach((rec) => {
          temp.push([rec.gender, parseFloat(rec.money), parseInt(rec.lessons)]);
        });
        temp.unshift(["Gender", "Money(£)", "Lessons"]);
        return temp;
      } else {
        return [];
      }
    },
    getMoneyPerPostcodeLineGraphArry() {
      let temp = [];
      if (this.tableData.postcode_totals) {
        this.tableData.postcode_totals.forEach((rec) => {
          temp.push([
            rec.postcode,
            parseFloat(rec.money),
            parseInt(rec.lessons),
          ]);
        });
        temp.unshift(["Postcode", "Money(£)", "Lessons"]);
        return temp;
      } else {
        return [];
      }
    },
    getMoneyPerPostcodeGenderLineGraphArry() {
      let temp = [];
      if (this.tableData.postcode_gender_totals) {
        this.tableData.postcode_gender_totals.forEach((rec) => {
          temp.push([
            `${rec.postcode}-${rec.gender}`,
            parseFloat(rec.money),
            parseInt(rec.lessons),
          ]);
        });
        temp.unshift(["Postcode and Gender", "Money(£)", "Lessons"]);
        return temp;
      } else {
        return [];
      }
    },
  },
};
</script>
