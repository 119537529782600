<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Chart</h3>
      <GChart
        type="LineChart"
        :settings="{ packages: ['corechart'] }"
        :data="getChartData"
        :options="chartOptions"
        :title="chartOptions.title"
      />
    </div>
  </v-col>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "FinancialPostcodeEarningsLineChart",
  components: { GChart },
  props: ["dataTable", "adiLang", "learnerLang", "teachGen"],
  data() {
    return {
      /*chartData: [
                [
                  "PostCode",
                  "Total Franchise Fee",
                 
                ],
                ["E20", 3404],
                ["E7", 1748],
                ["IG1", 900],
                ["W1C", 700],
              ],*/
      chartOptions: {
        chart: {
          title: "Franchise Fee by Instructor Postcode",
          subtitle: "Postcode And Total Franchise Fees",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        // isStacked: "percent",
        height: 600,
        vAxis: { minValue: 1 },
        is3D: true,

        lineWidth: 3,
        pointSize: 5,
        curveType: "function",
        animation: {
          startup: true,
          duration: 2000,
          easing: "out",
        },
      },
    };
  },
  methods: {
    getTableKeys(arr) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key = JSON.stringify(rec.key).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
  },
  computed: {
    getTableval(arr) {
      let keysAndValues = {};
      if (arr) {
        this.arr.forEach(function (rec) {
          var val = JSON.stringify(rec.count).replaceAll('""', "");
          keysAndValues.push(val);
        });
      }
      return keysAndValues;
    },
    //this will return google charData
    getChartData() {
      let ar = [];
      ar.push([
        "Outer Postcode",
        "Joining Fee (£)",
        "Total Franchise Fees (£)",
      ]);
      if (this.dataTable) {
        this.dataTable.forEach((ele) => {
          ar.push([
            ele.postcode,
            parseFloat(ele.initial_fees),
            parseFloat(ele.total),
          ]);
        });
      }

      //console.log(ar);
      return ar;
    },
  },
};
</script>
