import axios from "axios";
import {
  SET_DIALOG_ACTIVE,
  SET_DIALOG_INACTIVE,
  SET_BACKGROUND_SYNC_ACTIVE,
  SET_BACKGROUND_SYNC_INACTIVE,
  REGISTER_LEARNER,
  LOAD_RECENT_LEARNERS,
  LOAD_ASSINGED_LEARERS,
  GET_ASSINGED_LEARERS,
  IS_ASSINGED_LEARERS_LOADED,
  IS_RECENT_LEARNERS_LOADED,
  LOAD_ACTIVE_INSTRUCTORS,
  IS_ACTIVE_INSTRUCTORS_LOADED,
  GET_ADI_PENDING_LEARNERS,
  UPDATE_LEARNER,
  GET_SUITABLE_ADIS,
  SET_LEARNER_ADI,
  GET_ADIS_FOR_APPROVAL,
  GET_ADIS_FOR_APPROVAL_LOADING,
  SET_RECENT_LEARNERS,
  GET_RECENT_LEARNERS,
  GET_LEARNER_BY_ID,
  LOAD_ADI_PENDING_LEARNERS,
  IS_ADI_PENDING_LEARNERS_LOADED,
  LOAD_ADIS_FOR_APPROVAL,
  PROCESS_LEARNER_PAYMENT,
  LOAD_LEARNER_FEEDBACK_SUMMARY,
  GET_LEARNER_FEEDBACK_SUMMARY,
  SET_LEARNER_FEEDBACK_SUMMARY,
  IS_LEARNER_FEEDBACK_SUMMARY_LOADED,
  GET_INSTRUCTOR_FEEDBACK_SUMMARY,
  GET_ACTIVE_COMPLAINTS,
  GET_ACTIVE_INSTRUCTORS,
  GET_ADVANCE_INSTRUCTOR_SUMMARY,
  IS_ADVANCE_INSTRUCTOR_SUMMARY_LOADED,
  LOAD_ADI_SUMMARY,
  LOAD_NON_BOOKED_ADI_SUMMARY,
  IS_ADI_SUMMARY_LOADED,
  IS_NON_BOOKED_ADI_SUMMARY_LOADED,
  GET_NON_BOOKED_ADI_SUMMARY,
  GET_ADI_SUMMARY,
  GET_ADI_LEARNERS,
  GET_ADI_NON_BOOKED_LEARNERS,
  GET_ADI_CUREENT_BOOKINGS,
  GET_ADI_OLD_BOOKINGS,
  SET_BOOKIN_UPDATES,
  GET_LEARNER_CREDITS,
  SET_NEW_BOOKINGS,
  SET_TEST_OUTCOME,
  SET_LEARNER_DEPARTURE,
  SET_THEORY_TEST,
  SET_LEARNER_EYE_TEST,
  GET_PENDING_FEEDBACK_LEARNERS,
  GET_FEEDBACK_LEARNER_QUESTIONS,
  SAVE_LEARNER_FEEDBACK,
  GET_PENDING_FEEDBACK_INSTRUCTORS,
  GET_FEEDBACK_INSTRUCTOR_QUESTIONS,
  SAVE_INSTRUCTOR_FEEDBACK,
  GET_LEARNERLIST_FOR_ADI_FEEDBACKS,
  GET_ALL_LEARNER_FEEDBACKS,
  LOAD_INSTRUCTOR_FEEDBACK_SUMMARY,
  IS_INSTRUCTOR_FEEDBACK_SUMMARY_LOADED,
  SET_INSTRUCTOR_FEEDBACK_SUMMARY,
  GET_ADILIST_FOR_LEARNER_FEEDBACKS,
  GET_ALL_INSTRUCTOR_FEEDBACKS,
  FULL_RESET,
  GET_ALL_LEARNERS, //for complaints
  SET_ALL_INSTRUCTORS,
  GET_ALL_INSTRUCTORS,
  SAVE_NEW_COMPLAINT,
  LOAD_ACTIVE_COMPLAINTS,
  GET_COMPLAINT_UPDATES,
  SET_COMPLAINT_UPDATE,
  GET_RESOLVED_COMPLAINTS,
  SAVE_NEW_TRANSACTION,
  LOAD_NOTIFICATION_DATA,
  IS_NOTIFICATION_LOADING,
  GET_NOTIFICATION_DATA,
  SEARCH_LEARNER,
  LOAD_LEARER_DEPARTURE_INFO,
  LOAD_LEARNER_EYE_TEST,
  LOAD_LEARNER_DRIVING_TEST,
  LOAD_THEORY_TEST_INFO,
  LOAD_LEARNER_PAY_HISTORY,
  LOAD_LEARNER_BOOKINGS_HISTORY,
  LOAD_LEARNER_OVERVIEW_SENT_EMAILS,
  LOAD_LEARNER_OVERVIEW_GENERATED_PDFS,
  DOWNLOAD_OVERVIEW_PDF,
  SEND_LEARNER_OVERVIEW_PDF,
  LOAD_INSTRUCTOR_OVERVIEW_SENT_EMAILS,
  LOAD_INSTRUCTOR_OVERVIEW_GENERATED_PDFS,
  SEND_INSTRUCTOR_OVERVIEW_PDF,
  LOAD_LEARNER_CREDIT_SUMMARY,
  LOAD_LEARNER_PAID_CREDIT_INFO,
  LOAD_LEARNER_USED_CREDIT_INFO,
  LOAD_LEARNER_UNUSED_CREDIT_INFO,
  LOAD_LEARNER_FORFEIT_CREDIT_INFO,
  LOAD_INSTRUCTOR_LESSONS_SUMMARY,
  LOAD_ADI_NEW_LEARNER_DATA,
  LOAD_ADI_BOOKED_LESSONS_DATA,
  LOAD_ADI_RESCHEDULED_LESSONS_DATA,
  LOAD_ADI_CANCELED_LESSONS_DATA,
  LOAD_ADI_TEST_OUTCOMES_DATA,
  LOAD_ADI_FRANCHISE_DATA,
  LOAD_ADI_FRANCHISE_PAYMENTS_DATA,
  LOAD_MANAGEMENT_REPORT,
  LOAD_FEEDBACK_REPORT,
  LOAD_COMPLAINTS_REPORT,
  LOAD_INSTUCTOR_REPORTS,
  LOAD_FINANCIAL_REPORTS,
  M_COMMON,
} from "@/store/mutation-list.js";

export const officeUser = {
  namespaced: true,
  state: {
    recentLearners: {
      lst: [],
      isLoaded: false,
    },
    adiPendingLearners: {
      lst: [],
      isLoaded: false,
    },
    activeInstructors: {
      lst: [],
      isLoaded: false,
    },
    approvalPendingADIs: {
      lst: [],
      isLoaded: false,
    },
    ADISummary: {
      lst: [],
      isLoaded: false,
    },
    nonBookedLearnersADISummary: {
      lst: [],
      isLoaded: false,
    },
    assigned_learner_data: {
      lst: [],
      isLoaded: false,
    },

    learnerFeedbackSummary: { lst: [], isLoaded: false },

    instuctorFeedbackSummary: { lst: [], isLoaded: false },

    activeComplaints: { lst: [], isLoaded: false },

    instructorAdvanceSerchSummary: { lst: [], isLoaded: false },

    allInstructors: { lst: [], isLoaded: false },

    notificationData: { lst: [], isLoading: false },
  },
  getters: {
    [GET_RECENT_LEARNERS]: (state) => {
      //return state.recentLearners.lst;
      return state.recentLearners.lst;
    },
    [IS_RECENT_LEARNERS_LOADED]: (state) => {
      //return state.recentLearners.isLoaded;
      return state.recentLearners.isLoaded;
    },
    [IS_ADI_PENDING_LEARNERS_LOADED]: (state) => {
      //return state.recentLearners.isLoaded;
      return state.adiPendingLearners.isLoaded;
    },
    [GET_ADI_PENDING_LEARNERS]: (state) => {
      //return state.recentLearners.isLoaded;
      return state.adiPendingLearners.lst;
    },
    //there is an error. Need to find whats going on
    [GET_LEARNER_BY_ID]: (state) => (sid) => {
      return state.recentLearners.lst.find(({ stu }) => stu.student_id == sid);
    },
    [GET_LEARNER_FEEDBACK_SUMMARY]: (state) => {
      return state.learnerFeedbackSummary.lst;
    },
    [IS_LEARNER_FEEDBACK_SUMMARY_LOADED]: (state) => {
      if (state.learnerFeedbackSummary.isLoaded) {
        return true;
      } else {
        return false;
      }
    },
    [GET_INSTRUCTOR_FEEDBACK_SUMMARY]: (state) => {
      return state.instuctorFeedbackSummary.lst;
    },
    [IS_INSTRUCTOR_FEEDBACK_SUMMARY_LOADED]: (state) => {
      if (state.instuctorFeedbackSummary.isLoaded) {
        return true;
      } else {
        return false;
      }
    },
    [GET_ASSINGED_LEARERS]: (state) => {
      return state.assigned_learner_data.lst;
    },
    [IS_ASSINGED_LEARERS_LOADED]: (state) => {
      return state.assigned_learner_data.isLoaded;
    },

    [GET_ACTIVE_COMPLAINTS]: (state) => state.activeComplaints.lst,

    [GET_ACTIVE_INSTRUCTORS]: (state) => {
      //state.activeInstructors.lst;
      if (state.activeInstructors.lst) {
        return state.activeInstructors.lst.filter((adi) => adi.accState == 1);
      } else {
        return [];
      }
    },
    //this will return advance instructors summary data
    [GET_ADVANCE_INSTRUCTOR_SUMMARY]: (state) => state.activeInstructors.lst,
    [IS_ADVANCE_INSTRUCTOR_SUMMARY_LOADED]: (state) =>
      state.activeInstructors.isLoaded,
    [IS_ACTIVE_INSTRUCTORS_LOADED]: (state) => {
      return state.activeInstructors.isLoaded;
    },
    [GET_ADIS_FOR_APPROVAL]: (state) => {
      return state.approvalPendingADIs.lst;
    },
    [GET_ADIS_FOR_APPROVAL_LOADING]: (state) => {
      return state.approvalPendingADIs.isLoaded;
    },
    [IS_ADI_SUMMARY_LOADED]: (state) => {
      return state.ADISummary.isLoaded;
    },
    [GET_ADI_SUMMARY]: (state) => {
      return state.ADISummary.lst;
    },
    [IS_NON_BOOKED_ADI_SUMMARY_LOADED]: (state) => {
      return state.nonBookedLearnersADISummary.isLoaded;
    },
    [GET_NON_BOOKED_ADI_SUMMARY]: (state) => {
      return state.nonBookedLearnersADISummary.lst;
    },
    [GET_NOTIFICATION_DATA]: (state) => {
      return state.notificationData.lst;
    },
    [IS_NOTIFICATION_LOADING]: (state) => {
      return state.notificationData.isLoading;
    },
  },
  actions: {
    [REGISTER_LEARNER]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addstudentfrm", "addstudentfrm");
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            dispatch(`M_OU/LOAD_ADI_PENDING_LEARNERS`, null, { root: true });
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //this action will load learner and assiged adi info
    [LOAD_ASSINGED_LEARERS]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadAssignData", "loadAssignData");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("LOAD_ASSINGED_LEARERS", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //this action will load learner departure information
    [LOAD_LEARER_DEPARTURE_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getdepartureinfo", payload.learner);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //this action will load learner eye test information
    [LOAD_LEARNER_EYE_TEST]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearnereyetestinfo", payload.learner);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //this action will load learner driving test information
    [LOAD_LEARNER_DRIVING_TEST]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearnerdrivingtestinfo", payload.learner);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //this action will load learner driving test information
    [LOAD_THEORY_TEST_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearnertheorytestinfo", payload.learner);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //this action will load learner payments history information
    [LOAD_LEARNER_PAY_HISTORY]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearnerpaymenthistory", payload.learner);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_LEARNER_BOOKINGS_HISTORY]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearnerbookinghistory", payload.learner);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_RECENT_LEARNERS]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadStudentTable", "loadStudentTable");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("SET_RECENT_LEARNERS", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [UPDATE_LEARNER]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("savestudentchanges", payload.studentid);
      fd.append("studentid", payload.studentid);
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [PROCESS_LEARNER_PAYMENT]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("processStudentPayment", "processStudentPayment");
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_PENDING_LEARNERS]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadpendingpaidstudents", "loadpendingpaidstudents");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          // console.log(rep);
          commit("LOAD_ADI_PENDING_LEARNERS", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    [GET_SUITABLE_ADIS]({ rootGetters }, payload) {
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];
      //need to provide outer PC and student ID
      let indata = payload.form;

      let outerPC = indata.pc;
      outerPC = outerPC.split(" ")[0];
      let learnerID = indata.learnerID;

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getstudentinfowithsuitableadi", learnerID);
      fd.append("pupostcode", outerPC);

      return axios.post("ctrouV2.php", fd).then(function (response) {
        let rep = response.data;
        //console.log(rep);
        return rep;
      });
    },
    [SET_LEARNER_ADI]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("assignaditostudent", "assignaditostudent");

      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ACTIVE_INSTRUCTORS]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append(
        "loadallinstructorsseatchtable",
        "loadallinstructorsseatchtable"
      );

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("LOAD_ACTIVE_INSTRUCTORS", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADIS_FOR_APPROVAL]({ commit, dispatch, rootGetters }) {
      //need to set the background loaded
      //console.log("Loadding Office Staff");
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("loadPendingInstructorTableV2", "loadPendingInstructorTable");
      axios.post("ctrouV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_ADIS_FOR_APPROVAL", res);
        //console.log(response.data);
      });
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
        root: true,
      });
      //stop the background loading
    },

    //Booking related actions
    [LOAD_ADI_SUMMARY]({ commit, dispatch, rootGetters }) {
      //need to set the background loaded
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("getinstructorssummary", "getinstructorssummary");
      axios.post("ctrouV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_ADI_SUMMARY", res);
        //console.log(response.data);
      });
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
        root: true,
      });
      //stop the background loading
    },
    [LOAD_NON_BOOKED_ADI_SUMMARY]({ commit, dispatch, rootGetters }) {
      //need to set the background loaded
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append(
        "getinstructorssummaryfornonbookedlearners",
        "getinstructorssummaryfornonbookedlearners"
      );
      axios.post("ctrouV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_NON_BOOKED_ADI_SUMMARY", res);
        //console.log(response.data);
      });
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
        root: true,
      });
      //stop the background loading
    },
    [GET_ADI_LEARNERS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadinstructorslerners", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_ADI_NON_BOOKED_LEARNERS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadinstructorsnonbookedlerners", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_LEARNER_CREDITS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearneractivecredits", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_ADI_CUREENT_BOOKINGS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getinstructorsbookingdiary", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //this will load last 3 months bookings
    [GET_ADI_OLD_BOOKINGS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getinstructorsbookingdiaryhistory", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_NEW_BOOKINGS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("placenewbookings", payload.bookings);
      fd.append("adi", payload.adi);
      fd.append("learner", payload.learner);
      //pick up date
      if (payload.pickup.line1) {
        fd.append("line1", payload.pickup.line1);
      }
      if (payload.pickup.pc) {
        fd.append("pc", payload.pickup.pc);
      }
      //drop off data
      if (payload.dropoff.line1) {
        fd.append("dropline1", payload.dropoff.line1);
      }
      if (payload.dropoff.pc) {
        fd.append("droppc", payload.dropoff.pc);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_BOOKIN_UPDATES]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("savebookingupdates", payload.bookings);
      fd.append("adi", payload.adi);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_TEST_OUTCOME]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("savetestoutcome", payload.learner);
      fd.append("dateoftest", payload.testdate);
      fd.append("outcome", payload.result);
      fd.append("adiid", payload.adi);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_LEARNER_DEPARTURE]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("savedeparture", payload.learner);
      fd.append("dateofdep", payload.dateofdeparture);
      fd.append("reported", payload.reportedby);
      fd.append("reason", payload.reason);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    [SET_LEARNER_EYE_TEST]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("saveeyetest", payload.learner);
      fd.append("dateoftest", payload.dateoftest);
      fd.append("testoutcome", payload.outcome);
      fd.append("testedadi", payload.testedby);
      fd.append("nextsteps", payload.nextsteps);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_THEORY_TEST]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("savetheorytest", payload.learner);
      fd.append("dateoftest", payload.dateoftest);
      fd.append("mcqpoints", payload.mcqpoints);
      fd.append("hppoints", payload.hppoints);
      fd.append("outcome", payload.outcome);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //feedback related actions
    [GET_PENDING_FEEDBACK_LEARNERS]({ dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append(
        "getPendingStudentFeedbackTable",
        "getPendingStudentFeedbackTable"
      );

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_FEEDBACK_LEARNER_QUESTIONS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearnerfeedbackquestions", payload.bookingID);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_LEARNER_FEEDBACK]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("savelearnerfeedback", payload.feedbacks);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_PENDING_FEEDBACK_INSTRUCTORS]({ dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append(
        "getPendingInstructorFeedbackTable",
        "getPendingInstructorFeedbackTable"
      );

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_FEEDBACK_INSTRUCTOR_QUESTIONS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getinstructorfeedbackquestions", payload.bookingID);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_INSTRUCTOR_FEEDBACK]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("saveinstructorfeedback", payload.feedbacks);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_LEARNER_FEEDBACK_SUMMARY]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearneradifeedbacksummery", "getlearneradifeedbacksummery");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("SET_LEARNER_FEEDBACK_SUMMARY", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_LEARNERLIST_FOR_ADI_FEEDBACKS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getalllearneradifeedbackstudentlst", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_ALL_LEARNER_FEEDBACKS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getalllearneradifeedbackeachlessonforstudent", payload.adiid);
      fd.append("studentid", payload.learnerid);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_INSTRUCTOR_FEEDBACK_SUMMARY]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getadilearnerfeedbacksummery", "getadilearnerfeedbacksummery");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("SET_INSTRUCTOR_FEEDBACK_SUMMARY", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_ADILIST_FOR_LEARNER_FEEDBACKS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getalladilearnerfeedbackadilist", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_ALL_INSTRUCTOR_FEEDBACKS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getalladilearnerfeedbackeachlessonbyadiid", payload.learnerid);
      fd.append("adiid", payload.adiid);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //data for complaints
    [GET_ALL_LEARNERS]({ dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadStudentTable", "loadStudentTable");

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_ALL_INSTRUCTORS]({ commit, dispatch, rootGetters, state }) {
      if (!state.allInstructors.isLoaded) {
        dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
          root: true,
        });
        //SECURE Token
        const token = rootGetters["M_AUTH/TOKEN"];

        let fd = new FormData();
        fd.append("token", token); //adding token
        fd.append(
          "loadAllADIsTableForComplaints",
          "loadAllADIsTableForComplaints"
        );

        return axios
          .post("ctrouV2.php", fd)
          .then(function (response) {
            let rep = response.data;
            commit("SET_ALL_INSTRUCTORS", rep);
            return rep;
          })
          .finally(() => {
            dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
              root: true,
            });
          });
      } else {
        return new Promise((resolve) => {
          resolve(state.allInstructors.lst);
        });
      }
    },
    [SAVE_NEW_COMPLAINT]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("recordComplaint", "recordComplaint");
      let indata = payload.form;
      console.log(indata);

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return rep.result.ref;
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ACTIVE_COMPLAINTS]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getComplaintSummeryTable", "getComplaintSummeryTable");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("LOAD_ACTIVE_COMPLAINTS", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    [GET_COMPLAINT_UPDATES]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getComplaintUpdates", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_COMPLAINT_UPDATE]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("saveComplaintUpdate", payload.id);
      fd.append("pogressUpdate", payload.progress);
      fd.append("comState", payload.state);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [GET_RESOLVED_COMPLAINTS]({ dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append(
        "getResolvedComplaintSummeryTable",
        "getResolvedComplaintSummeryTable"
      );

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_NEW_TRANSACTION]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("recordadifranchisepayment", payload.id);
      fd.append("ref", payload.ref);
      fd.append("amount", payload.amount);
      fd.append("trdatetime", payload.trdatetime);
      fd.append("type", payload.type);
      fd.append("method", payload.method);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_NOTIFICATION_DATA]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      commit("IS_NOTIFICATION_LOADING", true);

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("ounotifications", "ounotifications");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          commit("LOAD_NOTIFICATION_DATA", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
          commit("IS_NOTIFICATION_LOADING", false);
        });
    },
    //payment report related actions
    [SEARCH_LEARNER]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("searchlearner", payload.keyword);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_LEARNER_CREDIT_SUMMARY]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadlearnercreditsummary", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    [LOAD_LEARNER_PAID_CREDIT_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadlearnerpayhistory", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_LEARNER_USED_CREDIT_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadcredusageinfo", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_LEARNER_UNUSED_CREDIT_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadunusedcredinfo", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_LEARNER_FORFEIT_CREDIT_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadwastedcredinfo", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_INSTRUCTOR_LESSONS_SUMMARY]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadinstructorlessonsummary", payload.id);
      fd.append("start", payload.start);
      fd.append("end", payload.end);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_NEW_LEARNER_DATA]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadinstructornewassinglearners", payload.id);
      fd.append("start", payload.start);
      fd.append("end", payload.end);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_BOOKED_LESSONS_DATA]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadinstructorbooingsfortime", payload.id);
      fd.append("start", payload.start);
      fd.append("end", payload.end);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_RESCHEDULED_LESSONS_DATA]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadinstructorreschedualbooingsfortime", payload.id);
      fd.append("start", payload.start);
      fd.append("end", payload.end);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_CANCELED_LESSONS_DATA]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadinstructorviewcanceledbooingsfortime", payload.id);
      fd.append("start", payload.start);
      fd.append("end", payload.end);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_TEST_OUTCOMES_DATA]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadtestoutcomeforaditimerange", payload.type);
      fd.append("adiid", payload.id);
      fd.append("start", payload.start);
      fd.append("end", payload.end);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_FRANCHISE_DATA]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadactiveadifranchisedata", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_FRANCHISE_PAYMENTS_DATA]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("loadinstructorfranchisepaymentrecords", payload.id);
      fd.append("start", payload.start);
      fd.append("end", payload.end);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    // Management Reports related actions
    [LOAD_MANAGEMENT_REPORT]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getreport", payload.reportNo);
      fd.append("start", payload.start);
      fd.append("end", payload.end);
      console.log(payload);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          console.log(rep);
          if (rep.result.code == 1) {
            return rep.result.data;
          }
          return "ERROR";
        })
        .catch(function (e) {
          console.log("error with data");
          console.log(e);
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_FEEDBACK_REPORT]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getfeedbackreport", payload.reportNo);
      fd.append("start", payload.start);
      fd.append("end", payload.end);
      console.log(payload);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          console.log(rep);
          if (rep.result.code == 1) {
            return rep.result.data;
          }
          return "ERROR";
        })
        .catch(function (e) {
          console.log("error with data");
          console.log(e);
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_COMPLAINTS_REPORT]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getcomplaintsreport", payload.reportNo);
      fd.append("start", payload.start);
      fd.append("end", payload.end);
      console.log(payload);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          console.log(rep);
          if (rep.result.code == 1) {
            return rep.result.data;
          }
          return "ERROR";
        })
        .catch(function (e) {
          console.log("error with data");
          console.log(e);
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_INSTUCTOR_REPORTS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getinstructormanagementreports", payload.reportNo);
      fd.append("start", payload.start);
      fd.append("end", payload.end);
      console.log(payload);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          console.log(rep);
          if (rep.result.code == 1) {
            return rep.result.data;
          }
          return "ERROR";
        })
        .catch(function (e) {
          console.log("error with data");
          console.log(e);
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_FINANCIAL_REPORTS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getfinancialmanagementreports", payload.reportNo);
      fd.append("start", payload.start);
      fd.append("end", payload.end);
      console.log(payload);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          console.log(rep);
          if (rep.result.code == 1) {
            return rep.result.data;
          }
          return "ERROR";
        })
        .catch(function (e) {
          console.log("error with data");
          console.log(e);
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //this section is for overview realted actions
    [LOAD_LEARNER_OVERVIEW_SENT_EMAILS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearneroverviewemailhistory", payload.learner);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_LEARNER_OVERVIEW_GENERATED_PDFS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getlearneroverviewpdflist", payload.learner);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [DOWNLOAD_OVERVIEW_PDF]({ dispatch, getters, rootGetters }, payload) {
      //need to set the background loaded
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request

      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      let indata = payload;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ovdocman.php", fd, { responseType: "blob" })
        .then(function (response) {
          let res = response.data;
          return res;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SEND_LEARNER_OVERVIEW_PDF]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("resendlearneroverviewpdf", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    //adi
    [LOAD_INSTRUCTOR_OVERVIEW_SENT_EMAILS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getinstructoroverviewemailhistory", payload.adi);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //
    [LOAD_INSTRUCTOR_OVERVIEW_GENERATED_PDFS](
      { dispatch, rootGetters },
      payload
    ) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getinstructoroverviewpdflist", payload.adi);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SEND_INSTRUCTOR_OVERVIEW_PDF]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("resendinstructoroverviewpdf", payload.id);

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          return rep;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
  },
  mutations: {
    [SET_RECENT_LEARNERS](state, payload) {
      //state.recentLearners = payload;
      state.recentLearners.lst = payload;
      state.recentLearners.isLoaded = true;
    },
    [LOAD_ADI_PENDING_LEARNERS](state, payload) {
      state.adiPendingLearners.lst = payload;
      state.adiPendingLearners.isLoaded = true;
    },
    [LOAD_ACTIVE_INSTRUCTORS](state, payload) {
      //console.log("JUST Commited this");
      state.activeInstructors.lst = payload;
      state.activeInstructors.isLoaded = true;
    },
    [LOAD_ADIS_FOR_APPROVAL](state, payload) {
      state.approvalPendingADIs.lst = payload;
      state.approvalPendingADIs.isLoaded = true;
    },
    [LOAD_ADI_SUMMARY](state, payload) {
      state.ADISummary.lst = payload;
      state.ADISummary.isLoaded = true;
    },
    [LOAD_NON_BOOKED_ADI_SUMMARY](state, payload) {
      state.nonBookedLearnersADISummary.lst = payload;
      state.nonBookedLearnersADISummary.isLoaded = true;
    },
    [SET_LEARNER_FEEDBACK_SUMMARY](state, payload) {
      state.learnerFeedbackSummary.lst = payload;
      state.learnerFeedbackSummary.isLoaded = true;
    },
    [SET_INSTRUCTOR_FEEDBACK_SUMMARY](state, payload) {
      state.instuctorFeedbackSummary.lst = payload;
      state.instuctorFeedbackSummary.isLoaded = true;
    },
    [SET_ALL_INSTRUCTORS](state, payload) {
      state.allInstructors.lst = payload;
      state.allInstructors.isLoaded = true;
    },
    [LOAD_ACTIVE_COMPLAINTS](state, payload) {
      state.activeComplaints.lst = payload;
      state.activeComplaints.isLoaded = true;
    },
    [LOAD_NOTIFICATION_DATA](state, payload) {
      state.notificationData.lst = payload;
      //state.notificationData.isLoading = false;
    },
    [IS_NOTIFICATION_LOADING](state, payload) {
      state.notificationData.isLoading = payload;
    },
    [LOAD_ASSINGED_LEARERS](state, payload) {
      state.assigned_learner_data.lst = payload;
      state.assigned_learner_data.isLoaded = true;
    },

    //this happens on logut
    [FULL_RESET](state) {
      //console.log("DUMMY Reset");
      //console.log(state.recentLearners);
      state.recentLearners.lst = [];
      state.recentLearners.isLoaded = false;
      state.activeInstructors = { lst: [], isLoaded: false };
      state.adiPendingLearners = {
        lst: [],
        isLoaded: false,
      };
      state.approvalPendingADIs = {
        lst: [],
        isLoaded: false,
      };
      state.allInstructors = {
        lst: [],
        isLoaded: false,
      };
      state.activeComplaints = {
        lst: [],
        isLoaded: false,
      };
      state.notificationData = {
        lst: [],
        isLoading: false,
      };
      state.nonBookedLearnersADISummary = {
        lst: [],
        isLoading: false,
      };
      state.assigned_learner_data = {
        lst: [],
        isLoading: false,
      };
    },
  },
};

/*
        //blocking spinner
      return new Promise((resolve) => {
        commit("SET_DIALOG_ACTIVE");
        console.log(payload);
        setTimeout(() => {
          commit("SET_DIALOG_INACTIVE");
          resolve("ERROR");
        }, 2000);
      });*/
