<template>
  <div class="row">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <InstructorTable
          title="Record Instructor Franchise Payments"
          :is-loading="isDataLoading"
          :dataarray="activeInstructors"
          :isSearch="true"
          sub-title="Please select an instructor"
          @choosen="selected"
        />
      </v-sheet>
      <v-dialog v-model="dialog" max-width="960px" scrollable persistent>
        <v-card>
          <v-card-title class="text-h5 blue lighten-2">
            <span>Accept Instructor Payments</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-simple-table v-if="selectedADI" class="text-left pa-5">
                <tr>
                  <th>ID</th>
                  <td>A{{ selectedADI.adi_id }}</td>
                  <th>Name</th>
                  <td>
                    {{
                      getName(
                        selectedADI.title,
                        selectedADI.fname,
                        selectedADI.lname
                      )
                    }}
                  </td>
                  <th>Mobile</th>
                  <td>{{ selectedADI.mobile | mobileno }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td>{{ selectedADI.email }}</td>
                  <th>Joined</th>
                  <td>{{ selectedADI.created }}</td>
                  <th>Account State</th>
                  <td>{{ selectedADI.accState | adiStatus }}</td>
                </tr>
              </v-simple-table>
              <v-divider></v-divider>
              <template>
                <div class="pa-5">
                  <h3 class="mb-2">Payment history (Last 6 months)</h3>
                  <v-data-table
                    :items="payHistory.lst"
                    :headers="payhisHeaders"
                    class="elevation-1"
                    :loading="payHistory.isLoading"
                    loading-text="Loading... Please wait"
                    hide-default-footer
                  >
                    <template v-slot:[`item.amount`]="{ item }">
                      <span>£{{ item.amount }}</span>
                    </template>
                    <template v-slot:[`item.date_of_transaction`]="{ item }">
                      <span>{{ item.date_of_transaction | dateReOrder }}</span>
                    </template>
                  </v-data-table>
                </div>
              </template>
              <v-divider></v-divider>
              <v-form class="pa-5">
                <v-row justify="center">
                  <v-col md="12">
                    <v-text-field
                      label="Reference"
                      outlined
                      clearable
                      v-model="form.ref"
                      counter="18"
                      :error-messages="refError"
                      @input="$v.form.ref.$touch()"
                      @blur="$v.form.ref.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col md="6">
                    <v-text-field
                      label="Amount"
                      outlined
                      prepend-inner-icon="mdi-currency-gbp"
                      type="number"
                      v-model.number="form.amount"
                      :error-messages="amountError"
                      @input="$v.form.amount.$touch()"
                      @blur="$v.form.amount.$touch()"
                    ></v-text-field
                  ></v-col>

                  <v-col md="6">
                    <v-select
                      label="Payment Type"
                      outlined
                      v-model="form.paymenttypeID"
                      :items="paymentTypes"
                      item-text="txt"
                      item-value="id"
                      :error-messages="paytypeError"
                      @input="$v.form.paymenttypeID.$touch()"
                      @blur="$v.form.paymenttypeID.$touch()"
                    ></v-select> </v-col
                ></v-row>
                <h3 class="mb-2">Card Details</h3>
                <v-row no-gutters>
                  <v-col md="6"
                    ><v-text-field
                      label="Name (as it appears on the card)"
                      placeholder="Name as it appears on the card"
                      outlined
                      clearable
                      counter="22"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Card Number"
                      placeholder="16 Digit Card number without spaces"
                      outlined
                      clearable
                      counter="16"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Expiary Date"
                      placeholder="MM/YY"
                      outlined
                      clearable
                      counter="5"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Security Code or CVV2"
                      placeholder="3 or 4 digits normally displayed on the back of the card"
                      outlined
                      clearable
                      counter="4"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <h3 class="mb-2">Billing Information</h3>
                <v-row no-gutters>
                  <v-col md="12">
                    <v-text-field
                      label="Card Holder's Name"
                      placeholder="Card holder's name"
                      outlined
                      clearable
                      counter="50"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="First Line of Address"
                      placeholder="First line of address where the card is registered"
                      outlined
                      clearable
                      counter="16"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Postcode"
                      placeholder="Postcode where the card is registered"
                      outlined
                      clearable
                      counter="9"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col md="12">
                    <v-text-field
                      label="Transaction Date &amp; Time"
                      outlined
                      type="datetime-local"
                      v-model="form.tDate"
                      :error-messages="dateError"
                      @input="$v.form.tDate.$touch()"
                      @blur="$v.form.tDate.$touch()"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col md="6"> </v-col>
                  <v-col md="6" class="float-right">
                    <v-btn class="float-right" @click="clearForm()"
                      >Reset</v-btn
                    >
                    <v-btn
                      class="primary mr-2 float-right"
                      @click="saveTransaction()"
                      >Record</v-btn
                    >
                  </v-col></v-row
                >
              </v-form>
              <v-divider></v-divider>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closed"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import InstructorTable from "@/components/Instructor/InstructorTable";
import {
  GET_ACTIVE_INSTRUCTORS,
  M_OU,
  SAVE_NEW_TRANSACTION,
  LOAD_ADI_FRANCHISE_PAYMENTS_DATA,
  M_DD,
  GET_ADI_PAYMENT_TYPES,
  GET_ADI_PAYMENT_METHODS,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
import {
  required,
  decimal,
  minValue,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "AcceptInstructorPayments",
  components: { InstructorTable },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Accept Instructor Payments",
          disabled: true,
          href: "/dashboard/acceptinstructorpayments",
        },
      ],
      dialog: false,
      selectedADI: null,
      form: {
        ref: null,
        amount: null,
        tDate: null,
        paymentmethodID: 2,
        paymenttypeID: 2,
      },
      payHistory: {
        lst: [],
        isLoading: false,
      },
      payhisHeaders: [
        { text: "ID", value: "transaction_id" },
        {
          text: "Ref",
          value: "reference",
        },
        { text: "Amount", value: "amount" },
        { text: "Type", value: "type_name" },
        { text: "Method", value: "method_name" },
        { text: "Date time", value: "date_of_transaction" },
        { text: "Recorded By", value: "staff_name" },
      ],
    };
  },
  validations: {
    form: {
      ref: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(18),
      },
      amount: { required, decimal, minValue: minValue(0.01) },
      tDate: { required },
      //paymentmethodID: { required },
      paymenttypeID: { required },
    },
  },
  methods: {
    ...mapActions({
      savePayment: `${M_OU}${SAVE_NEW_TRANSACTION}`,
      getLastPayRecs: `${M_OU}${LOAD_ADI_FRANCHISE_PAYMENTS_DATA}`,
    }),
    selected({ record }) {
      this.payHistory.isLoading = false;
      this.payHistory.lst = [];
      //console.log(record);
      this.selectedADI = record;
      this.dialog = true;
      //need to load history of franchise payments here
      //let sort start and end dates
      const today = new Date();
      let eDate = today.toLocaleDateString();
      eDate = eDate.replace(",", "");
      eDate = eDate.replace("/", "-");
      eDate = eDate.replace("/", "-");

      //remove 6 months
      today.setMonth(today.getMonth() - 6);
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        1,
        0,
        0,
        0
      );
      let sDate = startDate.toLocaleDateString();
      sDate = sDate.replace(",", "");
      sDate = sDate.replace("/", "-");
      sDate = sDate.replace("/", "-");

      this.payHistory.isLoading = true;

      this.getLastPayRecs({
        id: this.selectedADI.adi_id,
        start: sDate,
        end: eDate,
      })
        .then((recs) => {
          this.payHistory.lst = recs;
        })
        .finally(() => {
          this.payHistory.isLoading = false;
        });
    },
    closed() {
      this.dialog = false;
      this.selectedADI = null;
      this.clearForm();
    },
    getName(title, fname, lname) {
      return `${title} ${fname} ${lname}`;
    },
    clearForm() {
      this.$v.$reset();
      this.form = { ref: null, amount: null, tDate: null, tTime: null };
    },
    saveTransaction() {
      this.$v.$touch();
      if (this.$v.$invalid == false) {
        this.savePayment({
          id: this.selectedADI.adi_id,
          ref: this.form.ref,
          amount: this.form.amount,
          trdatetime: this.form.tDate.replace("T", " "),
          type: this.form.paymenttypeID,
          method: 2,
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Payment recorded successfuly.",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
            this.clearForm();
            this.dialog = false;
          } else {
            this.$fire({
              title: "Error",
              text: "Error recording payment. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      activeInstructors: `${M_OU}${GET_ACTIVE_INSTRUCTORS}`,
      paymentTypes: `${M_DD}${GET_ADI_PAYMENT_TYPES}`,
      paymentMethods: `${M_DD}${GET_ADI_PAYMENT_METHODS}`,
    }),
    isDataLoading() {
      return this.activeInstructors.length == 0 ? true : false;
    },
    //error section
    refError() {
      const errors = [];
      if (!this.$v.form.ref.$dirty) return errors;
      !this.$v.form.ref.minLength && errors.push("Reference is too short");
      !this.$v.form.ref.maxLength &&
        errors.push("Reference is too long. Max 18 characters");
      !this.$v.form.ref.required && errors.push("Reference is required.");

      return errors;
    },
    amountError() {
      const errors = [];
      if (!this.$v.form.amount.$dirty) return errors;
      !this.$v.form.amount.required && errors.push("Amount is required.");
      !this.$v.form.amount.minValue && errors.push("Invalid amount.");

      return errors;
    },
    dateError() {
      const errors = [];
      if (!this.$v.form.tDate.$dirty) return errors;
      !this.$v.form.tDate.required &&
        errors.push("Transaction date & time required.");

      return errors;
    },

    paytypeError() {
      const errors = [];
      if (!this.$v.form.paymenttypeID.$dirty) return errors;
      !this.$v.form.paymenttypeID.required &&
        errors.push("Transaction type required.");

      return errors;
    },
  },
};
</script>

<style></style>
